export default [
    {
        label: 'hospital_name',
        field: 'hospitalName',
        sort: false,
    },
    {
        label: 'therapist_name',
        field: 'therapistName',
        sort: false,
    },
    {
        label: 'patients_name',
        field: 'patientsName',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'onset',
        field: 'injuredDate',
        sort: true
    },
    {
        label: 'measured_date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'lt_shoulder_flexion',
        field: 'lt_shoulder_flexion',
    },
    {
        label: 'lt_shoulder_extension',
        field: 'lt_shoulder_extension',
    },
    {
        label: 'lt_shoulder_abduction',
        field: 'lt_shoulder_abduction',
    },
    {
        label: 'lt_shoulder_adduction',
        field: 'lt_shoulder_adduction',
    },
    {
        label: 'lt_elbow_flexion',
        field: 'lt_elbow_flexion',
    },
    {
        label: 'lt_elbow_extension',
        field: 'lt_elbow_extension',
    },
    {
        label: 'lt_wrist_flexion',
        field: 'lt_wrist_flexion',
    },
    {
        label: 'lt_wrist_extension',
        field: 'lt_wrist_extension',
    },
    {
        label: 'lt_hip_flexion',
        field: 'lt_hip_flexion',
    },
    {
        label: 'lt_hip_extension',
        field: 'lt_hip_extension',
    },
    {
        label: 'lt_hip_abduction',
        field: 'lt_hip_abduction',
    },
    {
        label: 'lt_hip_adduction',
        field: 'lt_hip_adduction',
    },
    {
        label: 'lt_knee_flexion',
        field: 'lt_knee_flexion',
    },
    {
        label: 'lt_ankle_dorsi_flexion',
        field: 'lt_ankle_dorsi_flexion',
    },
    {
        label: 'lt_ankle_plantar_flexion',
        field: 'lt_ankle_plantar_flexion',
    },
    {
        label: 'rt_shoulder_flexion',
        field: 'rt_shoulder_flexion',
    },
    {
        label: 'rt_shoulder_extension',
        field: 'rt_shoulder_extension',
    },
    {
        label: 'rt_shoulder_abduction',
        field: 'rt_shoulder_abduction',
    },
    {
        label: 'rt_shoulder_adduction',
        field: 'rt_shoulder_adduction',
    },
    {
        label: 'rt_elbow_flexion',
        field: 'rt_elbow_flexion',
    },
    {
        label: 'rt_elbow_extension',
        field: 'rt_elbow_extension',
    },
    {
        label: 'rt_wrist_flexion',
        field: 'rt_wrist_flexion',
    },
    {
        label: 'rt_wrist_extension',
        field: 'rt_wrist_extension',
    },
    {
        label: 'rt_hip_flexion',
        field: 'rt_hip_flexion',
    },
    {
        label: 'rt_hip_extension',
        field: 'rt_hip_extension',
    },
    {
        label: 'rt_hip_abduction',
        field: 'rt_hip_abduction',
    },
    {
        label: 'rt_hip_adduction',
        field: 'rt_hip_adduction',
    },
    {
        label: 'rt_knee_flexion',
        field: 'rt_knee_flexion',
    },
    {
        label: 'rt_ankle_dorsi_flexion',
        field: 'rt_ankle_dorsi_flexion',
    },
    {
        label: 'rt_ankle_plantar_flexion',
        field: 'rt_ankle_plantar_flexion',
    },

];
