export default [
    {
        label: 'hospital_name',
        field: 'hospitalName',
        sort: false,
    },
    {
        label: 'therapist_name',
        field: 'therapistName',
        sort: false,
    },
    {
        label: 'patients_name',
        field: 'patientsName',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'onset',
        field: 'injuredDate',
        sort: true
    },
    {
        label: 'measured_date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'abc_1',
        field: 'abc_1',
    },
    {
        label: 'abc_2',
        field: 'abc_2',
    },
    {
        label: 'abc_3',
        field: 'abc_3',
    },
    {
        label: 'abc_4',
        field: 'abc_4',
    },
    {
        label: 'abc_5',
        field: 'abc_5',
    },
    {
        label: 'abc_6',
        field: 'abc_6',
    },
    {
        label: 'abc_7',
        field: 'abc_7',
    },
    {
        label: 'abc_8',
        field: 'abc_8',
    },
    {
        label: 'abc_9',
        field: 'abc_9',
    },
    {
        label: 'abc_10',
        field: 'abc_10',
    },
    {
        label: 'abc_11',
        field: 'abc_11',
    },
    {
        label: 'abc_12',
        field: 'abc_12',
    },
    {
        label: 'abc_13',
        field: 'abc_13',
    },
    {
        label: 'abc_14',
        field: 'abc_14',
    },
    {
        label: 'abc_15',
        field: 'abc_15',
    },
    {
        label: 'abc_16',
        field: 'abc_16',
    },

];
