export default [
    {
        label: 'hospital_name',
        field: 'hospitalName',
        sort: false,
    },
    {
        label: 'therapist_name',
        field: 'therapistName',
        sort: false,
    },
    {
        label: 'patients_name',
        field: 'patientsName',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'onset',
        field: 'injuredDate',
        sort: true
    },
    {
        label: 'measured_date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'ma_1',
        field: 'ma_1',
    },
    {
        label: 'ma_2',
        field: 'ma_2',
    },
    {
        label: 'ma_3',
        field: 'ma_3',
    },
    {
        label: 'ma_4',
        field: 'ma_4',
    },
    {
        label: 'ma_5',
        field: 'ma_5',
    },
    {
        label: 'ma_6',
        field: 'ma_6',
    },
    {
        label: 'ma_7_kg_1',
        field: 'ma_kg_1',
    },
    {
        label: 'ma_7_kg_2',
        field: 'ma_kg_2',
    },
    {
        label: 'ma_7_kg_3',
        field: 'ma_kg_3',
    },
    {
        label: 'ma_8',
        field: 'ma_7',
    },
    {
        label: 'ma_9',
        field: 'ma_8',
    },
    {
        label: 'ma_10',
        field: 'ma_9',
    },
    {
        label: 'ma_11',
        field: 'ma_10',
    },
    {
        label: 'ma_12',
        field: 'ma_11',
    },
    {
        label: 'ma_13',
        field: 'ma_12',
    },
    {
        label: 'ma_14_pw_1',
        field: 'ma_pw_1',
    },
    {
        label: 'ma_14_pw_2',
        field: 'ma_pw_2',
    },
    {
        label: 'ma_14_pw_3',
        field: 'ma_pw_3',
    },
    {
        label: 'ma_15',
        field: 'ma_13',
    },
    {
        label: 'ma_16',
        field: 'ma_14',
    },
    {
        label: 'ma_17',
        field: 'ma_15',
    },
    {
        label: 'la_1',
        field: 'la_1',
    },
    {
        label: 'la_2',
        field: 'la_2',
    },
    {
        label: 'la_3',
        field: 'la_3',
    },
    {
        label: 'la_4',
        field: 'la_4',
    },
    {
        label: 'la_5',
        field: 'la_5',
    },
    {
        label: 'la_6',
        field: 'la_6',
    },
    {
        label: 'la_7_kg_1',
        field: 'la_kg_1',
    },
    {
        label: 'la_7_kg_2',
        field: 'la_kg_2',
    },
    {
        label: 'la_7_kg_3',
        field: 'la_kg_3',
    },
    {
        label: 'la_8',
        field: 'la_7',
    },
    {
        label: 'la_9',
        field: 'la_8',
    },
    {
        label: 'la_10',
        field: 'la_9',
    },
    {
        label: 'la_11',
        field: 'la_10',
    },
    {
        label: 'la_12',
        field: 'la_11',
    },
    {
        label: 'la_13',
        field: 'la_12',
    },
    {
        label: 'la_14_pw_1',
        field: 'la_pw_1',
    },
    {
        label: 'la_14_pw_2',
        field: 'la_pw_2',
    },
    {
        label: 'la_14_pw_3',
        field: 'la_pw_3',
    },
    {
        label: 'la_15',
        field: 'la_13',
    },
    {
        label: 'la_16',
        field: 'la_14',
    },
    {
        label: 'la_17',
        field: 'la_15',
    },
];
