export default [
    {
        label: 'hospital_name',
        field: 'hospitalName',
        sort: false,
    },
    {
        label: 'therapist_name',
        field: 'therapistName',
        sort: false,
    },
    {
        label: 'patients_name',
        field: 'patientsName',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'onset',
        field: 'injuredDate',
        sort: true
    },
    {
        label: 'measured_date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'assist',
        field: 'assist',
    },
    {
        label: 'pre_rest',
        field: 'pre_rest',
    },
    {
        label: 'pre_1',
        field: 'pre_1',
    },
    {
        label: 'pre_2',
        field: 'pre_2',
    },
    {
        label: 'pre_3',
        field: 'pre_3',
    },
    {
        label: 'pre_4',
        field: 'pre_4',
    },
    {
        label: 'pre_5',
        field: 'pre_5',
    },
    {
        label: 'pre_6',
        field: 'pre_6',
    },
    {
        label: 'distance_rest',
        field: 'distance_rest',
    },
    {
        label: 'distance_1',
        field: 'distance_1',
    },
    {
        label: 'distance_2',
        field: 'distance_2',
    },
    {
        label: 'distance_3',
        field: 'distance_3',
    },
    {
        label: 'distance_4',
        field: 'distance_4',
    },
    {
        label: 'distance_5',
        field: 'distance_5',
    },
    {
        label: 'distance_6',
        field: 'distance_6',
    },
    {
        label: 'comments_rest',
        field: 'comments_rest',
    },
    {
        label: 'comments_1',
        field: 'comments_1',
    },
    {
        label: 'comments_2',
        field: 'comments_2',
    },
    {
        label: 'comments_3',
        field: 'comments_3',
    },
    {
        label: 'comments_4',
        field: 'comments_4',
    },
    {
        label: 'comments_5',
        field: 'comments_5',
    },
    {
        label: 'comments_6',
        field: 'comments_6',
    },

];
