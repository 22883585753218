export default [
    {
        label: 'hospital_name',
        field: 'hospitalName',
        sort: false,
    },
    {
        label: 'therapist_name',
        field: 'therapistName',
        sort: false,
    },
    {
        label: 'patients_name',
        field: 'patientsName',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'onset',
        field: 'injuredDate',
        sort: true
    },
    {
        label: 'measured_date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'a_1_1',
        field: 'a_1_1',
    },
    {
        label: 'a_1_2',
        field: 'a_1_2',
    },
    {
        label: 'a_1_3',
        field: 'a_1_3',
    },
    {
        label: 'a_1_4',
        field: 'a_1_4',
    },
    {
        label: 'a_2_1',
        field: 'a_2_1',
    },
    {
        label: 'a_2_2',
        field: 'a_2_2',
    },
    {
        label: 'a_2_3',
        field: 'a_2_3',
    },
    {
        label: 'a_2_4',
        field: 'a_2_4',
    },
    {
        label: 'a_2_5',
        field: 'a_2_5',
    },
    {
        label: 'a_2_6',
        field: 'a_2_6',
    },
    {
        label: 'a_2_7',
        field: 'a_2_7',
    },
    {
        label: 'a_3_1',
        field: 'a_3_1',
    },
    {
        label: 'a_3_2',
        field: 'a_3_2',
    },
    {
        label: 'a_3_3',
        field: 'a_3_3',
    },
    {
        label: 'a_3_4',
        field: 'a_3_4',
    },
    {
        label: 'a_3_5',
        field: 'a_3_5',
    },
    {
        label: 'a_3_6',
        field: 'a_3_6',
    },
    {
        label: 'a_3_7',
        field: 'a_3_7',
    },
    {
        label: 'a_3_8',
        field: 'a_3_8',
    },
    {
        label: 'a_3_9',
        field: 'a_3_9',
    },
    {
        label: 'a_4_1',
        field: 'a_4_1',
    },
    {
        label: 'a_4_2',
        field: 'a_4_2',
    },
    {
        label: 'a_4_3',
        field: 'a_4_3',
    },
    {
        label: 'a_4_4',
        field: 'a_4_4',
    },
    {
        label: 'a_4_5',
        field: 'a_4_5',
    },
    {
        label: 'a_4_6',
        field: 'a_4_6',
    },
    {
        label: 'a_4_7',
        field: 'a_4_7',
    },
    {
        label: 'a_5_1',
        field: 'a_5_1',
    },
    {
        label: 'a_5_2',
        field: 'a_5_2',
    },
    {
        label: 'a_5_3',
        field: 'a_5_3',
    },
    {
        label: 'a_5_4',
        field: 'a_5_4',
    },
    {
        label: 'a_5_5',
        field: 'a_5_5',
    },
    {
        label: 'a_5_6',
        field: 'a_5_6',
    },
    {
        label: 'a_5_7',
        field: 'a_5_7',
    },
    {
        label: 'a_5_8',
        field: 'a_5_8',
    },
    {
        label: 'a_5_9',
        field: 'a_5_9',
    },
    {
        label: 'a_5_10',
        field: 'a_5_10',
    },
    {
        label: 'a_6_1',
        field: 'a_6_1',
    },
    {
        label: 'a_6_2',
        field: 'a_6_2',
    },
    {
        label: 'a_6_3',
        field: 'a_6_3',
    },
    {
        label: 'a_6_4',
        field: 'a_6_4',
    },
    {
        label: 'a_6_5',
        field: 'a_6_5',
    },
    {
        label: 'a_6_6',
        field: 'a_6_6',
    },
    {
        label: 'a_6_7',
        field: 'a_6_7',
    },
    {
        label: 'a_6_8',
        field: 'a_6_8',
    },
    {
        label: 'a_6_9',
        field: 'a_6_9',
    },
    {
        label: 'a_7_1',
        field: 'a_7_1',
    },
    {
        label: 'a_7_2',
        field: 'a_7_2',
    },
    {
        label: 'a_7_3',
        field: 'a_7_3',
    },
    {
        label: 'a_7_4',
        field: 'a_7_4',
    },
    {
        label: 'a_7_5',
        field: 'a_7_5',
    },
    {
        label: 'a_8_1',
        field: 'a_8_1',
    },
    {
        label: 'a_8_2',
        field: 'a_8_2',
    },
    {
        label: 'a_8_3',
        field: 'a_8_3',
    },
    {
        label: 'a_8_4',
        field: 'a_8_4',
    },
    {
        label: 'a_8_5',
        field: 'a_8_5',
    },
    {
        label: 'a_8_6',
        field: 'a_8_6',
    },
    {
        label: 'a_8_7',
        field: 'a_8_7',
    },
    {
        label: 'a_8_8',
        field: 'a_8_8',
    },
    {
        label: 'a_9_1',
        field: 'a_9_1',
    },

];
