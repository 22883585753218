export default [
    {
        label: 'hospital_name',
        field: 'hospitalName',
        sort: false,
    },
    {
        label: 'therapist_name',
        field: 'therapistName',
        sort: false,
    },
    {
        label: 'patients_name',
        field: 'patientsName',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'onset',
        field: 'injuredDate',
        sort: true
    },
    {
        label: 'measured_date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'sitting_static',
        field: 'sitting_static',
    },
    {
        label: 'sitting_dynamic',
        field: 'sitting_dynamic',
    },
    {
        label: 'standing_static',
        field: 'standing_static',
    },
    {
        label: 'standing_dynamic',
        field: 'standing_dynamic',
    },

];
