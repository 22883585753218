<template>
  <div class="main-container">
    <h5 class="main-title">평가 정보</h5>
    <div class="search-container">
      <p>조회 옵션 설정</p>
      <div class="guide-text">
        <img src="@/assets/images/IA/check-circle.png" alt="">
        <p>평가 도구 별 문항에 따른 점수 데이터는 하단의 엑셀파일 다운로드로 지원합니다.</p>
      </div>
      <div class="bs-flex">
        <h5 class="title">조회 기간 선택</h5>
        <mdb-select
            :scroll=false
            outline
            v-model="basicOptions"
            placeholder="전체 기간"
            @getValue="getSelectDateValue"
            class="search-select "/>
        <div class="select-picker">
          <mdb-date-picker-2 far datePickerIcon="fas fa-calendar" :class="{disableColor :isDateDisable === true}"
                             v-model="selectStartDate" class="data-pick" placeholder="0000-00-00" :disabled="isDateDisable"/>
          <p style="margin: 0 3px"> - </p>
          <mdb-date-picker-2 far datePickerIcon="fas fa-calendar" :class="{disableColor :isDateDisable === true}"
                             v-model="selectEndDate" class="data-pick" placeholder="0000-00-00" :disabled="isDateDisable"/>
        </div>
      </div>
      <div class="bs-flex patient-select" style="margin-top:10px">
        <h5 class="title">검색</h5>
        <div class="input-group">
          <div class="icon-input">
            <img src="@/assets/images/IA/icon_search.png" alt="search">
            <input v-model="search" placeholder="검색어를 입력해주세요." class="custom-input-box search-input"
                   v-on:keypress.enter.prevent="dataInit"/>
          </div>
          <button class="custom-btn-box search-btn" @click="dataInit">
            <img src="@/assets/images/IA/icon_user_2.png" alt="user">
            검색
          </button>
        </div>
      </div>
    </div>
    <div class="table-container">
      <mdb-datatable-2
          v-model="data"
          multiselectable
          class="text-center"
          hover
          striped
          fullPagination
          :searching="{value: search, field: fieldName}"
          @selected="onSelected($event)"
          noFoundMessage="검색된 데이터가 없습니다."/>
    </div>
    <!--    excel table-->
    <div>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataFm" id="excel-fm" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataBb" id="excel-bb" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataWmft" id="excel-wmft" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataSis" id="excel-sis" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataAaut" id="excel-aaut" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataMal" id="excel-mal" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataBbs" id="excel-bbs" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataTug" id="excel-tug" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataFtsts" id="excel-ftsts" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataFm_l" id="excel-fm_l" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataTmwt" id="excel-tmwt" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataSmwt" id="excel-smwt" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataDgi" id="excel-dgi" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataAbc" id="excel-abc" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataRom" id="excel-rom" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataMmt" id="excel-mmt" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataMas" id="excel-mas" :searching="{value: search, field: fieldName}"/>
      <mdb-datatable-2 v-show="false" :pagination="false" noFoundMessage="진행된 평가가 없습니다." v-model="excelDataBalance" id="excel-balance" :searching="{value: search, field: fieldName}"/>
    </div>
    <!--    excel table-->
    <div class="bs-flex">
      <button class="custom-btn-box exel-btn" @click="downExel">
        <img src="@/assets/images/IA/excel-download.png" alt="excel">
        Excel Download
      </button>
    </div>
  </div>
</template>

<script>
import {mdbDatatable2, mdbDatePicker2, mdbSelect} from "mdbvue";
import evaluation_list_columns from "@/assets/data/master/evaluation/evaluation_list_columns";
import {firebase} from "@/firebase/firebaseConfig";
import {getDate} from "@/lib/prettyDate";
import * as XLSX from "xlsx";
import excel_tool_bb_columns from "@/assets/data/masterExcelData/excel_tool_bb_columns";
import excel_tool_tug_columns from "@/assets/data/masterExcelData/excel_tool_tug_columns";
import excel_tool_sis_columns from "@/assets/data/masterExcelData/excel_tool_sis_columns";
import excel_tool_fm_columns from "@/assets/data/masterExcelData/excel_tool_fm_columns";
import excel_tool_mal_columns from "@/assets/data/masterExcelData/excel_tool_mal_columns";
import excel_tool_aaut_columns from "@/assets/data/masterExcelData/excel_tool_aaut_columns";
import excel_tool_wmft_columns from "@/assets/data/masterExcelData/excel_tool_wmft_columns";
import excel_tool_bbs_columns from "@/assets/data/masterExcelData/excel_tool_bbs_columns";
import excel_tool_ftsts_columns from "@/assets/data/masterExcelData/excel_tool_ftsts_columns";
import excel_tool_fm_l_columns from "@/assets/data/masterExcelData/excel_tool_fm_l_columns";
import excel_tool_tmwt_columns from "@/assets/data/masterExcelData/excel_tool_tmwt_columns";
import excel_tool_smwt_columns from "@/assets/data/masterExcelData/excel_tool_smwt_columns";
import excel_tool_dgi_columns from "@/assets/data/masterExcelData/excel_tool_dgi_columns";
import excel_tool_abc_columns from "@/assets/data/masterExcelData/excel_tool_abc_columns";
import excel_tool_rom_columns from "@/assets/data/masterExcelData/excel_tool_rom_columns";
import excel_tool_mmt_columns from "@/assets/data/masterExcelData/excel_tool_mmt_columns";
import excel_tool_mas_columns from "@/assets/data/masterExcelData/excel_tool_mas_columns";
import excel_tool_balance_columns from "@/assets/data/masterExcelData/excel_tool_balance_columns";
import questionAndAnswer from "@/assets/data/evaluationToolsInfo/questionAndAnswer";

export default {
  name: "EvaluationList",
  components: {
    mdbSelect,
    mdbDatePicker2,
    mdbDatatable2,
  },
  data() {
    return {
      autoDate: 0,
      basicOptions: [
        {text: "1주일", value: 7},
        {text: "1개월", value: 30},
        {text: "3개월", value: 90},
        {text: "6개월", value: 180},
        {text: "1년", value: 365},
        {text: "직접 선택", value: 0},
      ],
      selectStartDate: '',
      selectEndDate: '',
      search: '',
      fieldName: '',
      data: {columns: evaluation_list_columns, rows: []},
      excelDataFm: {columns: excel_tool_fm_columns, rows: []},
      excelDataBb: {columns: excel_tool_bb_columns, rows: []},
      excelDataWmft: {columns: excel_tool_wmft_columns, rows: []},
      excelDataSis: {columns: excel_tool_sis_columns, rows: []},
      excelDataAaut: {columns: excel_tool_aaut_columns, rows: []},
      excelDataMal: {columns: excel_tool_mal_columns, rows: []},
      excelDataBbs: {columns: excel_tool_bbs_columns, rows: []},
      excelDataTug: {columns: excel_tool_tug_columns, rows: []},
      excelDataFtsts: {columns: excel_tool_ftsts_columns, rows: []},
      excelDataFm_l: {columns: excel_tool_fm_l_columns, rows: []},
      excelDataTmwt: {columns: excel_tool_tmwt_columns, rows: []},
      excelDataSmwt: {columns: excel_tool_smwt_columns, rows: []},
      excelDataDgi: {columns: excel_tool_dgi_columns, rows: []},
      excelDataAbc: {columns: excel_tool_abc_columns, rows: []},
      excelDataRom: {columns: excel_tool_rom_columns, rows: []},
      excelDataMmt: {columns: excel_tool_mmt_columns, rows: []},
      excelDataMas: {columns: excel_tool_mas_columns, rows: []},
      excelDataBalance: {columns: excel_tool_balance_columns, rows: []},
      fbCollection: 'evaluations',
      isDateDisable: false,
      toolIndex: questionAndAnswer.tools,
      selectDataCount: 0,
    }
  },
  mounted() {
    this.dataInit();
  },
  methods: {
    dataInit() {
      const self = this;
      self.data.rows.splice(0)
      const db = firebase.firestore();
      let docBasic = db.collection(self.fbCollection)
          .where('state', '==', 1)
      if (self.selectStartDate !== '' && self.selectStartDate !== undefined) {
        let start = new Date(self.selectStartDate);
        start.setHours(0, 0, 0, 0)
        docBasic = docBasic
            .where('regDate', '>=', start)
        let end = new Date(self.selectEndDate);
        end.setHours(23, 59, 59, 999)
        docBasic = docBasic
            .where('regDate', '<=', end)
      }
      docBasic
          .orderBy("regDate", "desc")
          .get().then(async (querySnapshot) => {
        if (querySnapshot.size !== 0) {
          let i = 0
          querySnapshot.forEach((doc) => {
            const _data = doc.data();
            _data["docId"] = doc.id;
            const date = new Date(_data.regDate.seconds * 1000);
            _data.regDate = getDate(date);
            _data.injuredDate = _data.diseaseInfo.injuredDate;
            _data.hospitalName = _data.patientInfo.patientNo.split('_')[1]
            _data.therapistName = _data.therapistInfo.name
            _data.name = _data.patientInfo.name
            _data.diagnosis = _data.diseaseInfo.diagnosis
            _data.round = _data.round + '회차'
            if (_data.tools.fm.totalAll != null) _data.fmScore = _data.tools.fm.totalAll.toString()
            if (_data.tools.aaut.totalAouAvg != null) _data.aautAouAvg = _data.tools.aaut.totalAouAvg.toString()
            if (_data.tools.aaut.totalQomAvg != null) _data.aautQomAvg = _data.tools.aaut.totalQomAvg.toString()
            if (_data.tools.bb.ma != null) _data.bbScore = _data.tools.bb.ma.toString()
            if (_data.tools.wmft.totalAvg != null) _data.wmftTime = _data.tools.wmft.totalAvg.toString()
            if (_data.tools.mal.totalAouAvg != null) _data.malAouAvg = _data.tools.mal.totalAouAvg.toString()
            if (_data.tools.mal.totalQomAvg != null) _data.malQomAvg = _data.tools.mal.totalQomAvg.toString()
            if (_data.tools.sis.totalAvg != null) _data.sisScore = _data.tools.sis.totalAvg.toString()
            if (_data.tools.bbs !== undefined) {
              if (_data.tools.bbs.total != null) _data.bbsTotal = _data.tools.bbs.total.toString()
              if (_data.tools.tug.time != null) _data.tugTime = _data.tools.tug.time.toString()
              if (_data.tools.ftsts.time != null) _data.ftstsTime = _data.tools.ftsts.time.toString()
              if (_data.tools.fm_l.totalAll != null) _data.fm_lTotalAll = _data.tools.fm_l.totalAll.toString()
              if (_data.tools.tmwt.assistive_device != null) _data.tmwtSelfSelected = _data.tools.tmwt.fast_speed.average_self_selected.toString()
              if (_data.tools.tmwt.assistive_device != null) _data.tmwtFast = _data.tools.tmwt.self_selected_speed.average_self_selected.toString()
              if (_data.tools.smwt.distance_1 != null) _data.smwtTotal =
                  (_data.tools.smwt.distance_1 + _data.tools.smwt.distance_2 + _data.tools.smwt.distance_3 +
                      _data.tools.smwt.distance_4 + _data.tools.smwt.distance_5 + _data.tools.smwt.distance_6).toString()
              if (_data.tools.dgi.total != null) _data.dgiTotal = _data.tools.dgi.total.toString()
              if (_data.tools.abc.total != null) _data.abcTotal = _data.tools.abc.total.toString()
            }
            self.data.rows.push(_data);
            self.checkTools(self.data.rows[i].tools, _data.docId)
            i++

            delete _data.therapistInfo;
            delete _data.diseaseInfo;
            delete _data.patientInfo;
            delete _data.state;
          });
        }
      })
    },
    async checkTools(array1) {
      const self = this;

      const toolsToUpdate = {};

      const toolKeys = [
        'bm', 'rom_mmt', 'mas', 'balance', 'fm', 'bb', 'wmft', 'sis', 'aaut',
        'mal', 'bbs', 'tug', 'ftsts', 'fm_l', 'tmwt', 'smwt', 'dgi', 'abc'
      ];

      toolKeys.forEach(toolKey => {
        if (array1[toolKey] === undefined) {
          array1[toolKey] = self.toolIndex[toolKey];
          toolsToUpdate[toolKey] = self.toolIndex[toolKey];
        }
      });

    },

    getDateStr(myDate) {
      const year = myDate.getFullYear();
      const month = ("00" + (myDate.getMonth() + 1)).slice(-2)
      const day = ("00" + myDate.getDate()).slice(-2)
      return year + '-' + month + '-' + day
    },

    getSelectDateValue(value) {
      const self = this;
      let now = new Date();
      self.selectEndDate = this.getDateStr(now)
      const dDay = now.getDate();
      switch (value) {
        case 0 : {
          self.isDateDisable = false;
          self.selectEndDate = '';
          self.selectStartDate = '';
          return
        }
        default :
          now.setDate(dDay - value);
          self.isDateDisable = true;
          self.selectStartDate = this.getDateStr(now)
          return
      }
    },
    downExel() {
      const self = this;
      if (self.selectDataCount === 0) return alert('선택된 데이터가 없습니다.')

      let workBook = XLSX.utils.book_new(); // 새 시트 생성
      let excelFm = XLSX.utils.table_to_sheet(document.getElementById('excel-fm')); // table id
      XLSX.utils.book_append_sheet(workBook, excelFm, 'fm');
      let excelBb = XLSX.utils.table_to_sheet(document.getElementById('excel-bb'));
      XLSX.utils.book_append_sheet(workBook, excelBb, 'bb');
      let excelWmft = XLSX.utils.table_to_sheet(document.getElementById('excel-wmft'));
      XLSX.utils.book_append_sheet(workBook, excelWmft, 'wmft');
      let excelSis = XLSX.utils.table_to_sheet(document.getElementById('excel-sis'));
      XLSX.utils.book_append_sheet(workBook, excelSis, 'sis');
      let excelMal = XLSX.utils.table_to_sheet(document.getElementById('excel-mal'));
      XLSX.utils.book_append_sheet(workBook, excelMal, 'mal');
      let excelAaut = XLSX.utils.table_to_sheet(document.getElementById('excel-aaut'));
      XLSX.utils.book_append_sheet(workBook, excelAaut, 'aaut');
      let excelBbs = XLSX.utils.table_to_sheet(document.getElementById('excel-bbs'));
      XLSX.utils.book_append_sheet(workBook, excelBbs, 'bbs');
      let excelTug = XLSX.utils.table_to_sheet(document.getElementById('excel-tug'));
      XLSX.utils.book_append_sheet(workBook, excelTug, 'tug');
      let excelFtsts = XLSX.utils.table_to_sheet(document.getElementById('excel-ftsts'));
      XLSX.utils.book_append_sheet(workBook, excelFtsts, 'ftsts');
      let excelFm_l = XLSX.utils.table_to_sheet(document.getElementById('excel-fm_l'));
      XLSX.utils.book_append_sheet(workBook, excelFm_l, 'fm_l');
      let excelTmwt = XLSX.utils.table_to_sheet(document.getElementById('excel-tmwt'));
      XLSX.utils.book_append_sheet(workBook, excelTmwt, 'tmwt');
      let excelSmwt = XLSX.utils.table_to_sheet(document.getElementById('excel-smwt'));
      XLSX.utils.book_append_sheet(workBook, excelSmwt, 'smwt');
      let excelDgi = XLSX.utils.table_to_sheet(document.getElementById('excel-dgi'));
      XLSX.utils.book_append_sheet(workBook, excelDgi, 'dgi');
      let excelAbc = XLSX.utils.table_to_sheet(document.getElementById('excel-abc'));
      XLSX.utils.book_append_sheet(workBook, excelAbc, 'abc');
      let excelRom = XLSX.utils.table_to_sheet(document.getElementById('excel-rom'));
      XLSX.utils.book_append_sheet(workBook, excelRom, 'rom');
      let excelMmt = XLSX.utils.table_to_sheet(document.getElementById('excel-mmt'));
      XLSX.utils.book_append_sheet(workBook, excelMmt, 'mmt');
      let excelMas = XLSX.utils.table_to_sheet(document.getElementById('excel-mas'));
      XLSX.utils.book_append_sheet(workBook, excelMas, 'mas');
      let excelBalance = XLSX.utils.table_to_sheet(document.getElementById('excel-balance'));
      XLSX.utils.book_append_sheet(workBook, excelBalance, 'balance');

      const d = new Date();
      const today = d.getFullYear()
          + "-" + ("00" + (d.getMonth() + 1)).slice(-2)
          + "-" + ("00" + d.getDate()).slice(-2)
          + " " + ("00" + d.getHours()).slice(-2)
          + ":" + ("00" + d.getMinutes()).slice(-2)
          + ":" + ("00" + d.getSeconds()).slice(-2)
      XLSX.writeFile(workBook, 'NRF_평가정보_' + today + '.xlsx');
    },

    onSelected(value) {
      const self = this

      self.selectDataCount = value.length
      self.excelDataFm.rows.splice(0)
      self.excelDataBb.rows.splice(0)
      self.excelDataWmft.rows.splice(0)
      self.excelDataSis.rows.splice(0)
      self.excelDataAaut.rows.splice(0)
      self.excelDataMal.rows.splice(0)
      self.excelDataBbs.rows.splice(0)
      self.excelDataTug.rows.splice(0)
      self.excelDataFtsts.rows.splice(0)
      self.excelDataFm_l.rows.splice(0)
      self.excelDataTmwt.rows.splice(0)
      self.excelDataSmwt.rows.splice(0)
      self.excelDataDgi.rows.splice(0)
      self.excelDataAbc.rows.splice(0)
      self.excelDataRom.rows.splice(0)
      self.excelDataMmt.rows.splice(0)
      self.excelDataMas.rows.splice(0)
      self.excelDataBalance.rows.splice(0)

      for (let i = 0; i < value.length; i++) {

        const selectData = {
          patientsName: value[i].name,
          hospitalName: value[i].hospitalName,
          diagnosis: value[i].diagnosis,
          round: value[i].round,
          regDate: value[i].regDate,
          injuredDate: value[i].injuredDate,
          therapistName: value[i].therapistName,
        }

        if (value[i].tools.fm.state === 2) {
          const _data = {...value[i].tools.fm};
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataFm.rows.push(_data)
        }
        if (value[i].tools.bb.state === 2) {
          const _data = {...value[i].tools.bb}
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataBb.rows.push(_data)
        }
        if (value[i].tools.wmft.state === 2) {
          const _data = value[i].tools.wmft

          Object.entries(_data.la.kg).forEach((element) => {
            _data['la_' + element[0]] = element[1]
          })
          Object.entries(_data.la.pw).forEach((element) => {
            _data['la_' + element[0]] = element[1]
          })
          Object.entries(_data.la).forEach((element) => {
            _data[element[0]] = element[1]
          })
          Object.entries(_data.ma.kg).forEach((element) => {
            _data['ma_' + element[0]] = element[1]
          })
          Object.entries(_data.ma.pw).forEach((element) => {
            _data['ma_' + element[0]] = element[1]
          })
          Object.entries(_data.ma).forEach((element) => {
            _data[element[0]] = element[1]
          })
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataWmft.rows.push(_data)
        }
        if (value[i].tools.sis.state === 2) {
          const _data = {...value[i].tools.sis}
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataSis.rows.push(_data)
        }
        if (value[i].tools.aaut.state === 2) {
          const _data = value[i].tools.aaut

          Object.entries(_data.aou).forEach((element) => {
            _data[element[0]] = element[1]
          })
          Object.entries(_data.qom).forEach((element) => {
            _data[element[0]] = element[1]
          })
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataAaut.rows.push(_data)
        }
        if (value[i].tools.mal.state === 2) {
          const _data = value[i].tools.mal

          Object.entries(_data.aou).forEach((element) => {
            _data[element[0]] = element[1]
          })
          Object.entries(_data.qom).forEach((element) => {
            _data[element[0]] = element[1]
          })
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataMal.rows.push(_data)
        }

        if (value[i].tools.bbs.state === 2) {
          const _data = {...value[i].tools.bbs}
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataBbs.rows.push(_data)
        }
        if (value[i].tools.tug.state === 2) {
          const _data = {...value[i].tools.tug}
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataTug.rows.push(_data)
        }
        if (value[i].tools.ftsts.state === 2) {
          const _data = {...value[i].tools.ftsts}
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataFtsts.rows.push(_data)
        }
        if (value[i].tools.fm_l.state === 2) {
          const _data = {...value[i].tools.fm_l}
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataFm_l.rows.push(_data)
        }
        if (value[i].tools.tmwt.state === 2) {
          const _data = value[i].tools.tmwt

          Object.entries(_data.self_selected_speed).forEach((element) => {
            _data['self_selected_speed_' + element[0]] = element[1]
          })
          Object.entries(_data.fast_speed).forEach((element) => {
            _data['fast_speed_' + element[0]] = element[1]
          })
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataTmwt.rows.push(_data)
        }
        if (value[i].tools.smwt.state === 2) {
          const _data = {...value[i].tools.smwt}
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataSmwt.rows.push(_data)
        }
        if (value[i].tools.dgi.state === 2) {
          const _data = {...value[i].tools.dgi}
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataDgi.rows.push(_data)
        }
        if (value[i].tools.abc.state === 2) {
          const _data = {...value[i].tools.abc}
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataAbc.rows.push(_data)
        }

        if (value[i].tools.rom_mmt.state === 2) {
          const _data = value[i].tools.rom_mmt.rom

          Object.entries(_data.lt.ankle).forEach((element) => {
            _data['lt_ankle_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.elbow).forEach((element) => {
            _data['lt_elbow_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.forearm).forEach((element) => {
            _data['lt_forearm_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.hip).forEach((element) => {
            _data['lt_hip_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.knee).forEach((element) => {
            _data['lt_knee_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.shoulder).forEach((element) => {
            _data['lt_shoulder_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.wrist).forEach((element) => {
            _data['lt_wrist_' + element[0]] = element[1]
          })

          Object.entries(_data.rt.ankle).forEach((element) => {
            _data['rt_ankle_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.elbow).forEach((element) => {
            _data['rt_elbow_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.forearm).forEach((element) => {
            _data['rt_forearm_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.hip).forEach((element) => {
            _data['rt_hip_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.knee).forEach((element) => {
            _data['rt_knee_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.shoulder).forEach((element) => {
            _data['rt_shoulder_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.wrist).forEach((element) => {
            _data['rt_wrist_' + element[0]] = element[1]
          })
          Object.entries(_data).forEach((element) => {
            if (element[1] === 0)
              _data[element[0]] = element[1].toString()
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataRom.rows.push(_data)
        }
        if (value[i].tools.rom_mmt.state === 2) {
          const _data = value[i].tools.rom_mmt.mmt

          Object.entries(_data.lt.ankle).forEach((element) => {
            _data['lt_ankle_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.elbow).forEach((element) => {
            _data['lt_elbow_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.forearm).forEach((element) => {
            _data['lt_forearm_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.hip).forEach((element) => {
            _data['lt_hip_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.knee).forEach((element) => {
            _data['lt_knee_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.shoulder).forEach((element) => {
            _data['lt_shoulder_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.wrist).forEach((element) => {
            _data['lt_wrist_' + element[0]] = element[1]
          })

          Object.entries(_data.rt.ankle).forEach((element) => {
            _data['rt_ankle_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.elbow).forEach((element) => {
            _data['rt_elbow_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.forearm).forEach((element) => {
            _data['rt_forearm_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.hip).forEach((element) => {
            _data['rt_hip_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.knee).forEach((element) => {
            _data['rt_knee_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.shoulder).forEach((element) => {
            _data['rt_shoulder_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.wrist).forEach((element) => {
            _data['rt_wrist_' + element[0]] = element[1]
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataMmt.rows.push(_data)
        }
        if (value[i].tools.mas.state === 2) {
          const _data = value[i].tools.mas

          Object.entries(_data.lt.ankle).forEach((element) => {
            _data['lt_ankle_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.elbow).forEach((element) => {
            _data['lt_elbow_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.hip).forEach((element) => {
            _data['lt_hip_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.knee).forEach((element) => {
            _data['lt_knee_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.shoulder).forEach((element) => {
            _data['lt_shoulder_' + element[0]] = element[1]
          })
          Object.entries(_data.lt.wrist).forEach((element) => {
            _data['lt_wrist_' + element[0]] = element[1]
          })

          Object.entries(_data.rt.ankle).forEach((element) => {
            _data['rt_ankle_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.elbow).forEach((element) => {
            _data['rt_elbow_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.hip).forEach((element) => {
            _data['rt_hip_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.knee).forEach((element) => {
            _data['rt_knee_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.shoulder).forEach((element) => {
            _data['rt_shoulder_' + element[0]] = element[1]
          })
          Object.entries(_data.rt.wrist).forEach((element) => {
            _data['rt_wrist_' + element[0]] = element[1]
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataMas.rows.push(_data)
          self.excelDataMas.rows.toString()
        }
        if (value[i].tools.balance.state === 2) {
          const _data = value[i].tools.balance

          Object.entries(_data.standing).forEach((element) => {
            _data['standing_' + element[0]] = element[1]
          })
          Object.entries(_data.sitting).forEach((element) => {
            _data['sitting_' + element[0]] = element[1]
          })
          Object.entries(selectData).forEach((element) => {
            _data[element[0]] = element[1].toString()
          })
          self.excelDataBalance.rows.push(_data)
        }
      }
    }
  },
}
</script>

<style scoped>
.main-container {
  background: white;
  padding: 44px 20px;
  min-width: 890px;
}

.main-title {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 30px;
}

.guide-text {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.guide-text img {
  width: 18px;
  height: 18px;
  margin-right: 7px;
}

.guide-text p {
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 20px;
  margin: 0 !important;
}

.search-container {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 23px 20px;
}

.search-container > p {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #272833;
}

.bs-flex {
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: 16px;
}

.title {
  max-width: 100px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
  color: #272833;
  margin: 0 8px 0 0;
}

.search-select {
  width: 148px !important;
  margin-right: 8px;
}

.bs-flex .select-picker {
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;
}

/deep/ .mdb-datepicker-2 .form-control {
  height: 40px;
  margin: 0 !important;
  width: 269px;
}

/deep/ .data-pick .md-form, .mdb-datepicker-2 .md-form.md-outline {
  margin: 0 !important;
}

.search-input {
  max-width: 1000px !important;
  width: 100%;
  padding-left: 30px;
  font-size: 16px;
}

.input-group {
  display: flex;
  justify-content: flex-end;
  flex-wrap: nowrap;
}

.icon-input {
  margin-right: 8px;
  position: relative;
  min-width: 546px;
  width: 100%;
}


.icon-input img {
  position: absolute;
  top: 11px;
  left: 8px;
  z-index: 1;
  width: 18px;
  height: 18px;
}

.search-btn {
  width: 171px;
  height: 40px;
  background: #0069CA;
  border-radius: 4px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.search-btn img {
  margin-right: 6px;
  width: 18px;
}

.table-container {
  width: 100%;
  border: 1px solid #E1E3E6;
  box-sizing: border-box;
  border-radius: 8px;
  background: #ffffff;
  margin-top: 20px
}

/deep/
.form-check-input[type="checkbox"].filled-in:checked + label:after {
  background-color: #0069CA !important;
  border: 2px solid #0069CA !important;
}

/deep/ .mdb-datatable tr:nth-child(2n) {
  background-color: #F1F6FD;
}

/deep/ .mdb-datatable tr:nth-child(2n):HOVER {
  background-color: rgba(227, 238, 252, 1);
}

/deep/ .mdb-datatable thead th {
  font-weight: 700;
  padding-left: 0 !important;
  min-width: 89px;
  white-space: pre-wrap;
}

/deep/ .mdb-datatable thead th:first-child {
  padding-top: 16px;
}

/deep/ .mdb-datatable tbody td {
  padding-left: 0 !important;
  min-width: 89px;
}

/deep/ .mdb-datatable tbody td:first-child {
  min-width: 30px !important;
}

/deep/ .mdb-datatable thead th:first-child {
  min-width: 30px !important;
  text-align: center;
}

/deep/ td {
  text-align: center !important;
  cursor: pointer;
}

.disableColor {
  background: #EDEDF1;
}

.exel-btn {
  width: 185px;
  height: 40px;
  background: #08A86E;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.0025em;
  color: #FFFFFF;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0 0 auto;
}

.exel-btn img {
  width: 18px;
  margin: 0 15px 0 10px;
}

/deep/ .patient-select-box .md-form {
  width: 320px !important;
}

/deep/ .table-striped tbody tr:nth-of-type(odd) {
  background-color: #ffffff
}

/deep/ .form-check {
  padding-left: 23px;
}

/deep/ .search-select input {
  padding-left: 7.5px !important;
}

/deep/ .mdb-datepicker-2 input {
  padding-left: 7.5px !important;
}

/deep/ .mdb-datepicker-2 i {
  margin-top: 3px;
}

</style>
