export default [
    {
        label: 'hospital_name',
        field: 'hospitalName',
        sort: false,
    },
    {
        label: 'therapist_name',
        field: 'therapistName',
        sort: false,
    },
    {
        label: 'patients_name',
        field: 'patientsName',
        sort: true
    },
    {
        label: 'diagnosis',
        field: 'diagnosis',
        sort: true
    },
    {
        label: 'onset',
        field: 'injuredDate',
        sort: true
    },
    {
        label: 'measured_date',
        field: 'regDate',
        sort: true
    },
    {
        label: 'round',
        field: 'round',
        sort: true
    },
    {
        label: 'assistive_device',
        field: 'assistive_device',
    },
    {
        label: 'assist',
        field: 'assist',
    },
    {
        label: 'self_selected_speed_trial_1',
        field: 'self_selected_speed_trial_1',
    },
    {
        label: 'self_selected_speed_trial_2',
        field: 'self_selected_speed_trial_2',
    },
    {
        label: 'self_selected_speed_trial_3',
        field: 'self_selected_speed_trial_3',
    },
    {
        label: 'self_selected_speed_average_self_selected',
        field: 'self_selected_speed_average_self_selected',
    },
    {
        label: 'fast_speed_trial_1',
        field: 'fast_speed_trial_1',
    },
    {
        label: 'fast_speed_trial_2',
        field: 'fast_speed_trial_2',
    },
    {
        label: 'fast_speed_trial_3',
        field: 'fast_speed_trial_3',
    },
    {
        label: 'fast_speed_average_self_selected',
        field: 'fast_speed_average_self_selected',
    },

];
